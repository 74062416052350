<template>
  <ApplicationsTable :contractActions="true"></ApplicationsTable>
</template>

<script>
import ApplicationsTable from "../Applications/ApplicationsTable.vue";
export default {
  name: "Create contracts",
  components: {
    ApplicationsTable,
  },
  mounted() {
    this.$action("contracts-page-view");
  },
};
</script>
