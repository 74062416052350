<template>
  <div class="p-inputgroup w-13rem lg:w-auto">
    <InputText
      v-model="data.comments"
      :disabled="disableCommentValue"
      @keyup.enter="addComment(data, index)"
      @keyup="resetState($event, index)"
    >
    </InputText>
    <Button
      :id="`saved${index}`"
      :disabled="disableCommentValue"
      @click="addComment(data, index)"
      class="p-2 mdi mdi-18px mdi-content-save"
    >
      <!-- <span
                  v-if="data.status == 'draft' && animationIndex == true"
                  class="animation-fill-none scalein animation-iteration-1 animation-duration-2000 flex align-items-center justify-content-centerfont-bold text-white mdi mdi-18px mdi-content-save-check"
                ></span>
                <span
                  v-else-if="data.status == 'draft' && animationIndex == false"
                  class="scalein animation-iteration-1 animation-duration-2000 flex align-items-center justify-content-centerfont-bold text-white mdi mdi-18px mdi-content-save"
                ></span> -->
    </Button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Comment input",
  props: ["dataValue", "indexValue", "type"],
  computed: {
    data() {
      return this.dataValue;
    },
    index() {
      return this.indexValue;
    },
    ...mapGetters(["checkRole"]),
    disableComment() {
      return this.checkRole([
        "certification-manager",
        "quality-assurance-manager",
        "product-manager",
      ]);
    },
    disableCommentValue() {
      if (this.type == "contract") {
        return this.data.id ? false : true;
      } else {
        return this.disableComment;
      }
    },
  },

  methods: {
    resetState(event, index) {
      document.getElementById(`saved${index}`).className = "";

      document.getElementById(`saved${index}`).className +=
        "p-button p-component p-2 mdi mdi-18px mdi-content-save";
    },
    async addComment(value, index) {
      this.animationIndex = index;
      if (this.type == "application") {
        await this.$store.dispatch("addApplicationComment", value).then(() => {
          this.$action("application-comment-add", { applicationId: value.id });
        })
      } else if (this.type == "cases") {
        console.log(value);
        await this.$store.dispatch("updateCommentCase", {
          id: value.id,
          comments: value.comments,
          version: value.version,
        }).then(() => {
          if(this.$route.name == "auditCases") {
            this.$action("audit-case-comment-add", { caseId: value.id });
          }else {
            this.$action("case-comment-add", { caseId: value.id });
          }
        })
      } else if (this.type == "contract") {
        await this.$store.dispatch("updateContract", value).then(() => {
          this.$action("contract-comment-add", { contractId: value.id });
        })
      }else if(this.type == "renewal") {
        await this.$store.dispatch("updateRenewal", value).then(() => {
          this.$action("renewal-comment-add", { renewalId: value.id });
        })
      }

      document.getElementById(`saved${index}`).className = "";

      document.getElementById(`saved${index}`).className +=
        "p-button p-component p-2 animation-color animation-fill-forwards scalein animation-iteration-1 animation-duration-1000 flex align-items-center justify-content-centerfont-bold text-white mdi mdi-18px mdi-content-save-check";
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes animation-color {
  from {
    background-color: rgba(0, 169, 218, 1);
    color: #ffffff;
  }
  to {
    background-color: rgba(52, 199, 59, 1);
    color: #ffffff;
  }
}
.animation-color {
  animation: animation-color 1s linear;
}
</style>
